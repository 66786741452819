import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { UserInfo } from '@model/userInfo';
import { TranslateService } from '@ngx-translate/core';
import { AuthOperations } from '@operations-basics/auth.operations';
import { FulltextSearchServiceService } from 'src/app/data/services/fulltext-search-service.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  activeItem: string;
  isProducts: boolean = false
  isSupport: boolean = false
  isAboutUs: boolean = false
  lastElem = ''
  public isLangCZ: boolean = false
  public userInfo: UserInfo
  a = new FormControl('');

  @ViewChild('auto', { read: ViewContainerRef }) connectionInfos: ViewContainerRef;

  @Output()
  sidenavToggleEvent: EventEmitter<any> = new EventEmitter();
  private currentActiveMenuItem: string;

  @Input()
  set changes(change: any) {
    this.isMiniMenuNeed()
  }

  public vars = []

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private authOp: AuthOperations,
    private fulltext: FulltextSearchServiceService,
  ) { }

  ngOnInit(): void {
    this.isLangCZ = false
    if (this.translateService.currentLang === 'cz') {
      this.isLangCZ = true
    }

    this.translateService.onLangChange.subscribe(() => {
      this.isLangCZ = false
      if (this.translateService.currentLang === 'cz') {
        this.isLangCZ = true
      }
    })

    this.authOp.getCurrentUserAsObservable().subscribe({
      next: (data) => {
        this.userInfo = data;
      }
    })

    this.isMiniMenuNeed()
    this.currentActiveMenuItem = localStorage.getItem('currentActiveMenuItem')
  }

  isMiniMenuNeed() {
    if (this.router.url.includes('/products')) {
      this.isProducts = true
    } else if (this.router.url.includes('/support')) {
      this.isSupport = true

    } else if (this.router.url.includes('/about-us')) {
      this.isAboutUs = true
    } else {
      this.closeMiniMenu()
    }
  }

  closeMiniMenu() {
    this.isProducts = false
    this.isSupport = false
    this.isAboutUs = false
  }

  selectedInput(a) {
    let arrayOfInputs = document.querySelectorAll('input') as any

    arrayOfInputs[a].checked = true
  }

  setActiveItem(page: string) {
    this.activeItem = page;
  }

  openSupport() {
    this.closeMiniMenu();
    this.isSupport = true;
  }

  openAboutUs() {
    this.closeMiniMenu();
    this.isAboutUs = true;
  }

  openMegaMenu() {
    this.closeMiniMenu();
    this.isProducts = true;

    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  public isActive(id: string) {
    // return id === this.currentActiveMenuItem
    return this.router.url.includes(id);
  }

  public changeActiveMenuItem(id: string) {
    this.currentActiveMenuItem = id;
    localStorage.setItem('currentActiveMenuItem', this.currentActiveMenuItem);
  }

  public isDev(): boolean {
    // console.log(this.userInfo.roles);
    // console.log(this.userInfo.roles.find(role => role == UserRole.ESHOP_DEVELOPER) != undefine
    return false;
    // return this.userInfo?.roles.find(role => role == UserRole.ESHOP_DEVELOPER) != undefined;
  }

  public sidenavToggle() {
    this.sidenavToggleEvent.next(true);
  }

  public async onSearchInput() {
    let res: any = await this.fulltext.doSearch(this.a.value)
    this.vars = res.search_results
  }

  public clickResult(id: string, url: string) {
    let current_url = this.router.url.split('#')[0]
    if (url != current_url) {
      this.router.navigate([url], { fragment: id, skipLocationChange: false })
    } else {
      let element = document.getElementById(id)
      element.scrollIntoView()
    }
    this.vars = []
  }
}
